<template>
  <div
    class="bg-white container shadow-xl h-full flex flex-col items-center justify-center"
  >
    <div class="grid xl:gap-6 grid-cols-1 xl:grid-cols-2 w-full">
      <img
        src="/svg/undraw_authentication_fsn5.svg"
        alt="mot de passe oublié dok.ma"
        class="w-full hidden xl:block"
      />
      <div class="flex flex-col xl:justify-center">
        <router-link
          to="/"
          class="flex items-center justify-center mb-10"
          aria-label="dok.ma"
        >
          <img src="/logos/56/logo.png" alt="dok.ma" />
        </router-link>
        <h4
          class="font-semibold text-3xl text-blackdok mb-5 text-center xl:text-left font-EffraR"
        >
          Mot de passe oublié ?
        </h4>
        <ValidationObserver ref="observer">
          <form v-on:submit.prevent="onClickReset">
            <ValidationProvider
              name="Email"
              rules="required"
              id="email"
              class="w-full"
            >
              <div slot-scope="{ errors }">
                <dok-input
                  size="lg"
                  d-placeholder="Veuillez entrer votre adresse mail"
                  d-type="text"
                  :d-model.sync="email"
                  v-model="email"
                >
                </dok-input>
                <p v-if="errors[0]" class="text-red-700">
                  Veuillez remplir ce champ
                </p>
              </div>
            </ValidationProvider>
            <div class="mt-2 flex flex-col gap-y-5 w-full">
              <dok-button type="submit" bg="blue" size="lg">
                Réinitialiser le mot de passe
              </dok-button>

              <div class="text-center w-full">
                <router-link to="/" class="text-dokBlue-ultra font-semibold">
                  Retour à la page d'accueil
                </router-link>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      email: null
    };
  },
  methods: {
    ...mapActions("user", ["GET_USER_RESTPASSWOR"]),
    onClickReset: async function() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;

      this.$vs.loading({ background: "#000" });
      this.GET_USER_RESTPASSWOR({
        email: this.email,
        onData: this.onDataReset
      });
    },
    onDataReset: function({ ok, message }) {
      if (ok)
        this.$vs.notify({
          color: "success",
          text:
            "Veuillez vérifier votre boite mail pour réinitialiser votre mot de passe"
        });

      if (!ok)
        this.$vs.notify({
          color: "danger",
          text: "Erreur de réinitialisation du mot de passe"
        });

      this.$vs.loading.close();
    }
  }
};
</script>

<style scoped>
.card {
  @apply bg-gray-200;
}
</style>
